// extracted by mini-css-extract-plugin
export var defaultStyles = "hero-banner-module--defaultStyles--zV1FR";
export var flex = "hero-banner-module--flex--X2bEk";
export var leftContainer = "hero-banner-module--leftContainer--JuR1Q";
export var leftContainerCallout = "hero-banner-module--leftContainerCallout--iQDQE";
export var leftContainerMainImage = "hero-banner-module--leftContainerMainImage--Ib7sO";
export var mainHeading = "hero-banner-module--mainHeading--h68M1";
export var oneThird = "hero-banner-module--oneThird---RkT-";
export var rightContainer = "hero-banner-module--rightContainer--U5tDy";
export var rightContainerHeadingContainer = "hero-banner-module--rightContainerHeadingContainer--DVTtC";
export var twoThirds = "hero-banner-module--twoThirds--SDtkP";
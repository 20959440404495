import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { flex, defaultStyles, mainHeading, leftContainer, rightContainer, rightContainerHeadingContainer, twoThirds, oneThird, leftContainerMainImage, leftContainerCallout } from "./hero-banner.module.css"

export default function HeroBanner() {
    return (
        <section>
        <div className="container">
        <div className={flex}>
            <div className={[defaultStyles, leftContainer, twoThirds].join(" ")}>
                <div className={leftContainerMainImage}>
                <StaticImage src="../images/ifi-dna-home-kit-cover3.jpg" alt="Family looking into the horizon" imgStyle={{borderRadius: "20px 0 0  0"}} />
                </div>
                <div className={[leftContainerCallout, "animate__animated", "animate__pulse", "animate__infinite"].join(" ")}>
                    <p><span className="specialInlineLink blue-background">Scroll to see kits</span></p>
                </div>
            </div>
            <div className={[rightContainer, oneThird].join(" ")}>
                <div className={rightContainerHeadingContainer}>
                    <h2 className={mainHeading}>For Peace of Mind</h2>
                </div>
                <div>
                <ul>
                    <li>Non-legal, pain-free DNA Home Collection Kit</li>
                    <li>Results in as little as 3 business days upon receipt of delivery</li>
                    <li>Free shipping options available</li>
                    <li>Paternity, Maternity, Siblingship, Family Studies</li>
                    <li>Accredited Lab in Lombard IL</li>
                </ul>
                </div>
            </div>
        </div>
        </div>
      </section>
    )
}
import * as React from "react"
import { graphql} from "gatsby"
import { Layout } from "../components/layout"
import { ProductListing } from "../components/product-listing"
import HeroBanner from "../components/hero-banner"
import { ServiceCard } from "../components/service-card"
import servicesJson from "../../content/services.json"

export const query = graphql`
  query {
    shopifyCollection(handle: { eq: "frontpage" }) {
      products {
        ...ProductCard
      }
    }
  }
`

export default function IndexPage({ data }) {
 
  const {content} = servicesJson;
  
  return (
    <Layout>
      <HeroBanner />
      <section>
        <div className="container">
          <h2 className="title">Feature DNA Home Kits</h2>
          <div className="flex-section">
            <div className="two-thirds">
              <ProductListing products={data?.shopifyCollection?.products} />
            </div>
            <div className="one-third">
              {content.map(({btnLink, btnName, title, description, showAccreditation}, index)=>{
                return (
                  <ServiceCard key={index + title} btnLink={btnLink} btnName={btnName} title={title} description={description} showAccreditation={showAccreditation} isDark={true} />
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
